<template>
  <div v-if="loading" class="blur" style="position:absolute; z-index: 1000">
    <b-card class="loading-card d-flex flex-column" style="height: 310px; width: 220px">
      <lottie-animation :loop="true" :auto-play="true" :animationData="loadingAnimation"></lottie-animation>
      <div class="title-text text-center align-items-center justify-content-center">Идет генерация...</div>
      <div v-if="showProjectInfo" class="loading-project-id text-center align-items-center justify-content-center">
        ID проекта: {{ projectId }}
      </div>
      <div v-if="showTimeFromStart" class="loading-timer-text text-center align-items-center justify-content-center">
        {{ timeFromStart }}
      </div>
    </b-card>
  </div>
</template>

<script>

import loadingAnimation from "@/assets/animations/generating.json"
import LottieAnimation from "lottie-web-vue";

export default {
  name: "LoadingPopup",
  props: ["loading", "secondsPassed", "projectId"],
  components: {
    LottieAnimation
  },
  data() {
    return {
      loadingAnimation: loadingAnimation,
    }
  },
  computed: {
    timeFromStart() {
      let minutes = Math.floor(this.secondsPassed / 60)
      let seconds = this.secondsPassed % 60

      if (minutes < 10) {
        minutes = "0" + minutes.toString()
      } else {
        minutes = minutes.toString()
      }
      if (seconds < 10) {
        seconds = "0" + seconds.toString()
      } else {
        seconds = seconds.toString()
      }
      return minutes + ":" + seconds
    },

    showTimeFromStart() {
      return this.secondsPassed !== undefined && this.secondsPassed !== null
    },
    showProjectInfo() {
      return this.projectId !== undefined && this.projectId !== null && this.projectId !== "";
    },
  }
}
</script>

<style scoped>

.blur {
  backdrop-filter: blur(3px);
  width: 100%;
  height: 100%;
}

.loading-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 16px 16px;
  gap: 16px;

  position: absolute;
  width: 219.16px;
  height: 260.16px;
  left: 50%;
  top: 20%;

  background: #FEFEFE;

  border-radius: 28px;
  backdrop-filter: blur(3px);
  transform: translate(-50%, 0);
}
.loading-timer-text {
  font-weight: 400;
  font-size: 56px;
  line-height: 70px;
  display: flex;
  align-items: center;
  text-align: center;
}

</style>