import axiosInstance from '../axios'

const MapAPI = {
    methods: {

        checkShape: function(vertices) {
            return axiosInstance.post(process.env.VUE_APP_API_URL + `v1/map/checkShape`, vertices);
        },

        downloadDXF: function(taskId, variant, floor) {
            return axiosInstance.get(process.env.VUE_APP_API_URL + `v1/generation/downloadDXF/${taskId}/variant/${variant}/floor/${floor}`, {
                responseType: 'blob'
            });
        },

        downloadXLSX: function(taskId, variant, floor) {
            return axiosInstance.get(process.env.VUE_APP_API_URL + `v1/generation/downloadXLSX/${taskId}/variant/${variant}/floor/${floor}`, {
                responseType: 'blob'
            });
        },

        downloadXLSXTotal: function(taskId, variant) {
            return axiosInstance.get(process.env.VUE_APP_API_URL + `v1/generation/downloadXLSXEconomyParameters/${taskId}/variant/${variant}`, {
                responseType: 'blob'
            });
        },

        downloadZip: function(taskId, variant) {
            return axiosInstance.get(process.env.VUE_APP_API_URL + `v1/generation/downloadZipDXF/${taskId}/variant/${variant}`, {
                responseType: 'blob'
            });
        },

        checkPing: function() {
            return axiosInstance.get(process.env.VUE_APP_API_URL + `v1/status/ping`);
        },

        checkVersion: function() {
            return axiosInstance.get(process.env.VUE_APP_API_URL + `v1/status/buildInfo`);
        },


        getAreaLimits: function () {
            try {
                return axiosInstance.get(process.env.VUE_APP_API_URL + 'v1/map/getAreaLimits');

            } catch (error) {
                console.error('Error fetching area limits:', error);
                return null;
            }
        },

        getGenerationResult: function(taskId, responseType = 'ALL') {
            console.log("getGenerationResult");
            return axiosInstance.get(process.env.VUE_APP_API_URL + `v1/generation/${taskId}`, {
                params: {
                    responseType: responseType
                }
            });
        },

        getGenerationFloorGeoJSON: function(floor, variant, taskId) {
            console.log("getGenerationFloorGeoJSON")
            return axiosInstance.get(process.env.VUE_APP_API_URL + `v1/generation/downloadFloorPlan/${taskId}/variant/${variant}/floor/${floor}`)
        },

        generateConcept: function(request) {
            return axiosInstance.post(process.env.VUE_APP_API_URL + `v1/generation/start`, request);
        },

        processXLSXFile: function(file) {
            let formData = new FormData();
            formData.append('file', file);
            return axiosInstance.post(process.env.VUE_APP_API_URL + `v1/xlsx/convertApartments`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        },

        getBuildingLibraries: function() {
            return axiosInstance.get(process.env.VUE_APP_API_URL + `v1/libraries/list`)
        },

        getSectionLibraries: function() {
            return axiosInstance.get(process.env.VUE_APP_API_URL + `v1/sections/list`)
        },
    }
};

export default MapAPI